import React, { useRef, useImperativeHandle, forwardRef } from "react";

import Link from "./util/GatsbyLink";
import Flicking from "@egjs/react-flicking";
import { Fade, AutoPlay } from "@egjs/flicking-plugins";

/** @jsx jsx */
import { jsx, css, Text } from "theme-ui";

function Carousel(props, ref) {
  const [moving, setMoving] = React.useState(0);
  const plugins = [new Fade(), new AutoPlay(2000, "NEXT")];
  const flicking = useRef();

  useImperativeHandle(ref, () => ({
    next: () => {
      flicking.current.next();
    },
    prev: () => {
      flicking.current.prev();
    },
  }));

  return (
    <Flicking
      tag="div"
      viewportTag="div"
      cameraTag="div"
      classPrefix="eg-flick"
      deceleration={0.0075}
      horizontal={true}
      circular={true}
      infinite={false}
      infiniteThreshold={0}
      lastIndex={Infinity}
      threshold={40}
      duration={100}
      panelEffect={(x) => 1 - Math.pow(1 - x, 3)}
      defaultIndex={0}
      inputType={["touch", "pointer"]}
      thresholdAngle={45}
      bounce={10}
      autoResize={true}
      adaptive={false}
      zIndex={1000}
      bound={false}
      overflow={false}
      hanger={"50%"}
      anchor={"50%"}
      gap={25}
      moveType={{ type: "snap", count: 1 }}
      collectStatistics={false}
      onMoveStart={(e) => {
        setMoving(true);
      }}
      onMoveEnd={(e) => {
        setMoving(false);
      }}
      //plugins={plugins}
      ref={flicking}
    >
      {React.Children.toArray(props.children).map((c) =>
        c.props.clickHandler
          ? React.cloneElement(c, {
              clickHandler: () => (!moving ? c.props.clickHandler() : null),
            })
          : c
      )}
    </Flicking>
  );
}

export default Carousel = forwardRef(Carousel);

export function CarouselItemContainer({
  children,
  clickHandler,
  key,
  navigate, //TODO big problem, clicked on swipe!!!!!
}) {
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "1vh",
        paddingBottom: "1vh",
        cursor: clickHandler ? "pointer" : "default",
      }}
      key={key}
      onClick={clickHandler}
    >
      {navigate ? <Link to={navigate}>{children}</Link> : children}
    </div>
  );
}
