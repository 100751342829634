import React, { useRef, useState, forwardRef } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Carousel from "./Carousel.jsx";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { IconButton } from "theme-ui";
import TextField from "@material-ui/core/TextField";
import { useSpring, animated, useTransition } from "react-spring";

import useMeasure from "react-use-measure";
import Link from "./util/GatsbyLink";

/** @jsx jsx */
import { jsx, css, Text } from "theme-ui";

const defaultBgColors = [
  "#F5F5F5",
  //["#FFAB91", "#FFCC80", "#FFF59D", "#C5E1A5", "#81D4FA", "#F5F5F5"],
  "white",
];

const defaultButtonIconColors = ["#757575", "#757575"];

export default function MainPageGridItem({
  children,
  colorNumber = 0,
  bgColors = defaultBgColors,
  xs,
  sm,
  flexDirection = "column",
  alignItems = "center",
  justifyContent = "center",
  lateralPadding = "8px",
  topBottomPadding = "2vh",
}) {
  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      sx={{
        paddingTop: topBottomPadding,
        paddingBottom: topBottomPadding,
        paddingLeft: lateralPadding,
        paddingRight: lateralPadding,
        bg: bgColors[colorNumber % bgColors.length],
        display: "flex",
        flexDirection: flexDirection,
        alignItems: alignItems,
        justifyContent: justifyContent,
      }}
    >
      {children}
    </Grid>
  );
}

export const CarouselButtonsStyles = {
  lateral: "lateral",
  bottom: "bottom",
  none: "none",
};

export const pageMargins = {
  externalPageMargins: ["5vw", "7vw", "10vw", "10vw", "20vw", "20vw"],
};

export const pageWidths = {
  maxElementWidths: ["90vw", "86vw", "80vw", "80vw", "60vw", "60vw"],
};

export const CarouselButtonIcons = {
  halfArrows: [<ArrowLeftIcon />, <ArrowRightIcon />],
  fullArrows: [],
};

export function GridItemCarousel({
  children,
  xs = 12,
  sm,
  title = "Carousel Title",
  colorNumber = 0,
  bgColors = defaultBgColors,
  buttonStyle = CarouselButtonsStyles.lateral,
  mobileButtons = false,
  mobileBreakpoint = 1, //TODO
  buttonSize = "5vh", //TODO array support
  buttonIcons = CarouselButtonIcons.halfArrows,
  buttonIconColors = defaultButtonIconColors,
}) {
  const carousel = useRef();

  const buttonColor = buttonIconColors[colorNumber % buttonIconColors.length];
  const leftArrow = React.cloneElement(buttonIcons[0], {
    style: {
      fontSize: buttonSize,
      color: buttonColor,
    },
  });
  const rightArrow = React.cloneElement(buttonIcons[1], {
    style: {
      fontSize: buttonSize,
      color: buttonColor,
    },
  });
  const leftArrowButton = (
    <IconButton
      sx={{
        height: "auto",
        width: "auto",
      }}
      onClick={() => carousel.current.prev()}
    >
      {leftArrow}
    </IconButton>
  );
  const rightArrowButton = (
    <IconButton
      sx={{
        height: "auto",
        width: "auto",
      }}
      onClick={() => carousel.current.next()}
    >
      {rightArrow}
    </IconButton>
  );
  return (
    <MainPageGridItem
      xs={xs}
      sm={sm}
      colorNumber={colorNumber}
      bgColors={bgColors}
      lateralPadding="0px"
    >
      <Text variant="paragraphTitle" sx={{ color: "black" }}>
        {title}
      </Text>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        sx={{
          width: "100%",
          boxSizing: "content-box",
        }}
      >
        <Box
          sx={{
            display:
              buttonStyle !== CarouselButtonsStyles.lateral
                ? "none"
                : mobileButtons
                ? "flex"
                : ["none", "flex"],
            alignItems: "center",
            justifyContent: "flex-end",
            flexGrow: "1",
            flexShrink: "1",
            flexBasis: "0",
            minWidth: "0",
            margin: "2px",
          }}
        >
          {leftArrowButton}
        </Box>
        <Box
          sx={{
            flexGrow: "10",
            flexShrink: "1",
            flexBasis: "0",
            minWidth: "0",
          }}
        >
          <Carousel ref={carousel}>{children}</Carousel>
        </Box>
        <Box
          sx={{
            display:
              buttonStyle !== CarouselButtonsStyles.lateral
                ? "none"
                : mobileButtons
                ? "flex"
                : ["none", "flex"],
            alignItems: "center",
            justifyContent: "flex-start",
            flexGrow: "1",
            flexShrink: "1",
            flexBasis: "0",
            minWidth: "0",
            margin: "2px",
          }}
        >
          {rightArrowButton}
        </Box>
      </Box>
      <Box
        sx={{
          display:
            buttonStyle !== CarouselButtonsStyles.bottom
              ? "none"
              : mobileButtons
              ? "flex"
              : ["none", "flex"],
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {leftArrowButton}
        <IconButton
          sx={{
            height: "auto",
            width: "auto",
          }}
        >
          <div
            sx={{
              fontSize: buttonSize,
              color: buttonColor,
            }}
          >
            /
          </div>
        </IconButton>
        {rightArrowButton}
      </Box>
    </MainPageGridItem>
  );
}

export function GridItemParagraph({
  xs = 12,
  sm,
  colorNumber = 1,
  bgColors,
  title,
  content = "This is a really long, maybe not, default content",
}) {
  const displayTitle = title !== undefined && title !== null;
  return (
    <MainPageGridItem
      xs={xs}
      sm={sm}
      colorNumber={colorNumber}
      bgColors={bgColors}
      flexDirection="column"
      alignItems="center"
      lateralPadding="0px"
    >
      <div
        sx={{
          width: pageWidths.maxElementWidths,
          marginLeft: pageMargins.externalPageMargins,
          marginRight: pageMargins.externalPageMargins,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Text
          variant="paragraphTitle2"
          sx={{ color: "black", display: displayTitle ? "block" : "none" }}
        >
          {title}
        </Text>
        <Text variant="default">{content}</Text>
      </div>
    </MainPageGridItem>
  );
}

export function GridItemPhotoStuff({
  children,
  xs = 12,
  sm,
  breakpoint,
  img = "/cardDefaultLogo.png",
  colorNumber = 1,
  bgColors,
  leftPhoto = true,
  minHeight = ["auto", "30vh"],
  maxPhotoHeightVMode = "50vh",
  maxPhotoWidthHMode = "40vw",
  centerHMode = false,
  centerVMode = true,
}) {
  const [ref, { height }] = useMeasure();
  const el1MarginLeft = pageMargins.externalPageMargins;
  const el1MarginRight = ["5vw", "1vw"];
  return (
    <MainPageGridItem
      xs={xs}
      sm={sm}
      colorNumber={colorNumber}
      bgColors={bgColors}
      flexDirection={["column", "row"]}
      //alignItems={["center", "stretch"]}
      alignItems={centerVMode ? "center" : "flex-start"}
      justifyContent={[
        "center",
        centerHMode ? "center" : leftPhoto ? "flex-start" : "flex-end",
      ]}
      lateralPadding="0px"
    >
      <div
        sx={{
          width: [pageWidths.maxElementWidths[0], "auto"],
          minHeight: minHeight,
          height: ["100%", height], //TODO expand with text on right
          maxHeight: [maxPhotoHeightVMode, "auto"],
          marginLeft: leftPhoto ? el1MarginLeft : el1MarginRight,
          marginRight: leftPhoto ? el1MarginRight : el1MarginLeft,
          marginBottom: ["1vh", "0"],
          order: [1, leftPhoto ? 1 : 2],
        }}
      >
        <img
          src={img}
          alt={img}
          sx={{
            objectFit: "contain",
            objectPosition: [
              "50% 50%",
              leftPhoto ? "right center" : "left center",
            ],
            // width: "inherit",
            // height: "inherit",

            height: ["inherit", "100%"],
            width: ["100%", "auto"],
            maxWidth: ["auto", maxPhotoWidthHMode],
          }}
        />
      </div>
      <div
        sx={{
          width: [pageWidths.maxElementWidths[0], "auto"],
          marginLeft: leftPhoto ? el1MarginRight : el1MarginLeft,
          marginRight: leftPhoto ? el1MarginLeft : el1MarginRight,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          order: [2, leftPhoto ? 2 : 1],
        }}
        ref={ref}
      >
        {children}
      </div>
    </MainPageGridItem>
  );
}

export function GridItemPhotoText({
  xs = 12,
  sm,
  breakpoint,
  img = "/wide-doctors.jpg",
  colorNumber = 1,
  bgColors,
  leftPhoto = true,
  title,
  content = "This is a really long, maybe not, default content",
  centerVMode = true,
}) {
  const displayTitle = title !== undefined && title !== null;
  return (
    <GridItemPhotoStuff
      xs={xs}
      sm={sm}
      breakpoint={breakpoint}
      img={img}
      colorNumber={colorNumber}
      bgColors={bgColors}
      leftPhoto={leftPhoto}
      centerVMode={centerVMode}
    >
      <Text
        variant="paragraphTitle2"
        sx={{ color: "black", display: displayTitle ? "block" : "none" }}
      >
        {title}
      </Text>
      <Text variant="default">{content}</Text>
    </GridItemPhotoStuff>
  );
}

export function GridItemCardList({
  children,
  xs = 12,
  sm,
  breakpoint,
  colorNumber = 1,
  bgColors,
  title,
  collapsable,
  buttonLateralMargin = "2vh",
}) {
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();
  const [childRef, { height: childHeight }] = useMeasure();
  const [query, setQuery] = useState([]);
  const [active, setActive] = useState(false); //TODO prova a capire perchè si renderizza due volte ad altezza 0
  const [open, setOpen] = useState(false); //TODO prova a capire perchè si renderizza due volte ad altezza 0
  var obj = collapsable
    ? {
        height: `${childHeight}px`,
        overflow: "hidden",
      }
    : {};
  if (open || (active && !collapsable)) {
    obj = { height: `${height}px` };
  }
  //if (active) obj = { height: "30vh", overflow: "hidden" };
  const spring = useSpring(obj);
  const springTop = useSpring({
    ...obj,
    marginBottom: open ? `${height * -1}px` : `${childHeight * -1}px`,
  });
  const spin = useSpring({
    config: { friction: 10 },
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
  });
  if (height > 0 && !active) {
    setActive(true);
  }
  return (
    <MainPageGridItem
      xs={xs}
      sm={sm}
      colorNumber={colorNumber}
      bgColors={bgColors}
      flexDirection="column"
      alignItems="center"
      lateralPadding={0}
    >
      {title && (
        <Text variant="paragraphTitle2" sx={{ color: "black" }}>
          {title}
        </Text>
      )}
      <div
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
        }}
      >
        {/* <div sx={{ display: "flex", alignItems: "center" }}>
          <SearchIcon sx={{ height: "1.3em", width: "1.3em" }} />
        </div> */}
        <TextField
          id="filled-basic"
          label="Cerca"
          variant="filled"
          sx={{
            marginLeft: "1vw",
          }}
          onChange={(e) => setQuery(e.target.value.toLowerCase().split(" "))}
        />
      </div>
      <animated.div style={spring}>
        <div
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            boxSizing: "content-box",
            flexWrap: "wrap",
            flexGrow: "1",
            flexShrink: "1",
          }}
          ref={ref}
        >
          {React.Children.toArray(children).map((c, i) =>
            React.cloneElement(c, {
              query: query,
              ref: i == 0 ? childRef : undefined,
            })
          )}
        </div>
      </animated.div>
      <div
        sx={{
          width: "100%",
          marginBottom: -54,
          position: "relative",
          top: -54,
          display: collapsable && height > childHeight ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "flex-end",
          pointerEvents: "none",
        }}
      >
        <animated.button
          style={spin}
          sx={{
            //fontSize: heights.map((h) => "calc(" + 0.09 + "*" + h + ")"),
            //height: "auto",
            variant: "buttons.roundedGray",
            height: ["46px", "54px"],
            width: ["46px", "54px"],
            padding: "6px",
            marginRight: "2vh",
            borderWidth: "0px",
            pointerEvents: "auto",
          }}
          onClick={() => setOpen(!open)}
        >
          <ArrowDownIcon sx={{ height: "100%", width: "auto" }} />
        </animated.button>
      </div>
    </MainPageGridItem>
  );
}

export function CardListContainer(
  { children, queryText, query, clickHandler, navigate },
  ref
) {
  const display = query.every(
    (q) => queryText.toLowerCase().search(q) != -1 || q === ""
  );
  const transitions = useTransition(display, null, {
    initial: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  // return transitions.map(
  //   ({ item, key, props }) =>
  //     item && (
  //       <animated.div
  //         sx={{
  //           paddingTop: "1vh",
  //           paddingBottom: "1vh",
  //           paddingLeft: "1vh",
  //           paddingRight: "1vh",
  //         }}
  //       >
  //         {children}
  //       </animated.div>
  //     )
  // );

  return (
    <div
      sx={{
        paddingTop: "1vh",
        paddingBottom: "1vh",
        paddingLeft: "1vh",
        paddingRight: "1vh",
        display: display ? "block" : "none",
        cursor: clickHandler ? "pointer" : "default",
      }}
      onClick={clickHandler} //TODO verifica se prende sia il click handler che il navigate sotto
      ref={ref}
    >
      {navigate ? <Link to={navigate}>{children}</Link> : children}
    </div>
  );
}

CardListContainer = forwardRef(CardListContainer);

export function getParagraphItem(
  title,
  content,
  colorNumber,
  leftPhoto,
  image,
  key = 0,
  xs = 12,
  sm = undefined,
  centerVMode = true
) {
  if (image == null || image == undefined) {
    return (
      <GridItemParagraph
        key={key}
        xs={xs}
        sm={sm}
        colorNumber={colorNumber}
        title={title}
        content={content}
      />
    );
  } else {
    return (
      <GridItemPhotoText
        key={key}
        xs={xs}
        sm={sm}
        colorNumber={colorNumber}
        title={title}
        content={content}
        img={image.publicUrl}
        leftPhoto={leftPhoto}
        centerVMode={centerVMode}
      />
    );
  }
}
